* { font-family: SquareNeo-Regular, serif; }
.App { width: 100%; min-height: 100vh; display: flex; justify-content: center; align-items: center; flex-direction: column; }
.Header { position: absolute;  min-width:1000px; height: 100px; padding-bottom:200px; width: 100%; top:0; left:0; right:0; z-index: 1; display:flex; justify-content: center; padding-top:80px; flex-direction: column;
   color: #FFF; background: linear-gradient(to left,rgb(77, 149, 232),rgb(30, 50, 116) 100%, #000); }
.Header-Title { font-size: 47px; text-align: center; font-family: SquareNeo-Bold, serif; }
.Header-Sub-Title { font-size: 18px; margin-top:20px; text-align: center; }
.Contents-Container { position: relative; z-index: 1; width: 1000px; min-height: 100%; display:flex; align-items: center; justify-content: center; }
.Contents { margin: 240px 0; width: 1100px; height:500px; display:flex; flex-wrap: wrap; background-color: #FFF;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%), 0 2px 4px rgb(0 0 0 / 7%), 0 4px 8px rgb(0 0 0 / 7%), 0 8px 16px rgb(0 0 0 / 7%), 0 16px 32px rgb(0 0 0 / 7%), 0 32px 64px rgb(0 0 0 / 7%) }
.Result-Contents { position:relative; width:35%; height:100%; background: linear-gradient(to bottom,rgb(77, 149, 232),rgb(30, 50, 116) 100%, #000); color: #FFFFFF; overflow: hidden; }
.Result-Title { margin-bottom: 15px; width: 100%; height: 60px; display: flex; align-items: center; box-sizing: border-box; padding-left: 15px; font-size: 16px; background: linear-gradient(to bottom,rgb(87, 163, 237),rgb(66, 123, 192) 100%, #000); }
.Result-Value { width: 100%; box-sizing: border-box; display:flex; align-items: center; padding: 11px 30px; font-size: 15px; }
.Result-Value span { font-size: 25px; font-family: SquareNeo-Bold, serif; }
.Result-Image{ position:absolute; width: 200px; bottom: 20px; right:-10px; }



.Calculator-Contents { width: 65%; height:100%; }
.Calculator-Values { width: 100%; height: 100%; box-sizing: border-box; display:flex; flex-direction: column; justify-content: center; align-items: center; }


.Calculator-Text { width: 450px; display:flex; justify-content: flex-start; align-items: center; padding: 15px 0; font-size: 15px; }
.Calculator-Inputs { width: 100%; display: flex; justify-content: center; align-items: center; }

input[type=range] { -webkit-appearance: none; background: transparent; }
.Calculator-Values input[type=range]{ width: 350px; height: 6px; background: #ddd; border-radius: 5px; border: none; z-index: 2222; }
.Calculator-Values input[type=range]::-webkit-slider-thumb{ -webkit-appearance: none; width: 20px; height: 20px; border-radius: 50%; background: #664AFF; border: 1px solid #664AFF; cursor: pointer; }
.Calculator-Values input[type=range]::-moz-range-thumb{ -webkit-appearance: none; width: 20px; height: 20px; border-radius: 50%; background: #664AFF; border: 1px solid #664AFF; cursor: pointer; }
.Calculator-Values input[type=range]::-moz-range-progress{ background: #664AFF; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Calculator-Inputs input[type=number]{ width: 80px; height: 40px; outline: none; border-radius: 8px; border: 1px #ddd solid; margin-left: 10px; padding:0 5px; font-size:14px; text-align: center; }

.Footer { width:100%; display: flex; justify-content: center; align-items: center; font-size:15px; color:white; background: black; padding: 15px 0; }

@media(max-width: 1100px) {
    .Contents { width: 100%; }
}

@media(max-width: 1000px) {
    .Contents-Container { width: 100%; }
    .Header{ box-sizing: border-box; padding-left: 5px; padding-right: 5px; padding-bottom: 150px; height: 300px; }
}

@media(max-width: 900px) {
    .Calculator-Contents, .Result-Contents { width: 100%; }
    .Result-Contents { height: auto; padding-bottom: 170px; }
}

@media(max-width: 600px) {
    .Header-Title{ font-size: 32px; }
    .Header-Sub-Title{ font-size: 14px; }
}

@media(max-width: 450px) {
    .Calculator-Text{ width:100%; justify-content: center; font-size: 12px; box-sizing: border-box; padding: 15px 5px; }
    .Calculator-Inputs{ box-sizing: border-box; padding: 0 5px; }
}


@font-face {
    font-family: "SquareNeo-Light";
    src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-aLt.otf");
}
@font-face {
    font-family: "SquareNeo-Regular";
    src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-bRg.otf");
}
@font-face {
    font-family: "SquareNeo-Bold";
    src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-cBd.otf");
}
@font-face {
    font-family: "SquareNeo-EB";
    src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-dEb.otf");
}
@font-face {
    font-family: "SquareNeo-Heavy";
    src: url("../src/assets/fonts/SquareNeo/NanumSquareNeoOTF-eHv.otf");
}
